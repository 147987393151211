import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { signInWithGoogle, logout } from '../firebase';
import { useSelector } from 'react-redux';
import { selectUser } from '../features/userSlice';

function Menu() {
  const location = useLocation();
  const user = useSelector(selectUser);
  let content = 
              <Nav.Link eventKey='5'>
                <Button as="a" variant='link' onClick={signInWithGoogle}>
                  Sign In
                </Button>
              </Nav.Link>;
  if (user) {
    content = 
      <Nav.Link eventKey='5'>
        <Image src={user.photoURL} roundedCircle fluid className='avatar' />
        {user.displayName}
        <Button as="a" variant='link' onClick={logout}>Sign Out</Button>
      </Nav.Link>
  }
  
  return (
      <Navbar className="ps-3 pe-4 mb-2" bg='light' sticky='top' collapseOnSelect expand='lg'
      >
        <Navbar.Brand href="#home">Touring</Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav' className='justify-content-between'>
          <Nav variant='tabs' defaultActiveKey='/'>
            <Nav.Link eventKey='1' as={Link} to="/" exact='true' className={location.pathname === '/' ? 'active' : ''}>Home</Nav.Link>
            <Nav.Link eventKey='2' as={Link} to="/tours" className={location.pathname.startsWith('/tours') ? 'active' : ''}>Tours</Nav.Link>
            <Nav.Link eventKey='3' as={Link} to="/latestRide" className={location.pathname === '/latestRide' ? 'active' : ''}>Latest Ride</Nav.Link>
            <Nav.Link eventKey='4' as={Link} to="/manageContent" className={location.pathname === '/manageContent' ? 'active' : ''}>Manage Content</Nav.Link>
          </Nav>
          <Navbar.Text>
            {content}
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
  );
}

export default Menu;