import { createSlice } from "@reduxjs/toolkit";

export const selectedRiderSlice = createSlice({
  name: 'selectedRider',
  initialState: { value: null},
  reducers: {
    setRider: (state, action) => {
      state.value = action.payload;
    }, 
    unsetRider: state => {
      state.value = null;
    }
  }
});

export const { setRider, unsetRider } = selectedRiderSlice.actions;
export const selectRider = state => state.selectedRider.value;
export default selectedRiderSlice.reducer;