import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectRider } from '../features/selectedRiderSlice';
import Badge from 'react-bootstrap/Badge'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { db } from '../firebase'
import { collection, orderBy, query, onSnapshot } from 'firebase/firestore';
import { useUtils } from '../utils/utils';

function RideMarkers() {
  const { getFormattedDateTime, formatTextDate } = useUtils();
  const rider = useSelector(selectRider);
  const [locs, setLocs] = useState([]);
  const markersRef = useRef([]);
  const map = useMap();
  const markerLib = useMapsLibrary('marker');

  //Sets up a subscription to rider's location updates for the day. 
  useEffect(() => {
    console.log('in useEffect', !!rider);
    if (!rider) {
      return;
    }

    const locationsRef = collection(db, `/users/${rider.uid}/rides/${rider.latestRideId}/locations`)
    const q = query(locationsRef, orderBy('time'));
    const locationsUnsubscribe = onSnapshot(q, locationsSnapshot => {
      const newLocs = []
      //Process all location updates
      console.log('Number of updates: ', locationsSnapshot.docs.length);
      locationsSnapshot.docs.forEach((docSnapshot) => {
        newLocs.push({...docSnapshot.data()});
      });

      setLocs(newLocs);
    });
    return () => {
      console.log('Locations unsubscribed');
      locationsUnsubscribe();
    };
  }, [rider])

  const getRideStatus = () => {
    return locs.some(loc => loc.type === 'last') ? {status: 'Ended', color: 'success'} : {status: 'In Progress', color: 'info'};
  }

  const getLastUpdateTime = () => {
    const dateTimeDisplay = locs[locs.length - 1].time.seconds ? 
                              getFormattedDateTime(locs[locs.length - 1].time.seconds * 1000) : 
                              getFormattedDateTime(locs[locs.length - 1].time);

    return dateTimeDisplay;
  }

  const getRiderNameDisplay = () => {
    return `${rider.name}'s`;
  }
  const Link = ({ id, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <span><u>{children}</u></span>
    </OverlayTrigger>
  );

  //Programmatically remove old markers and add new markers
  const setMarkers = () => {
    if (!rider || !map || !markerLib) {
      return false;
    }

    const greenMarkerOptions = {background: '#82E0AA', glyphColor: '#F4D03F'};
    const redMarkerOptions = {background: 'red'};

    //remove existing markers
    markersRef.current.forEach(marker => {
      console.log('deleting marker');
      marker.map = null;
    });
    markersRef.current = [];
          
    const newMarkers = []; 
    let bounds = new window.google.maps.LatLngBounds();

    const lastIndex = locs.length - 1;
    locs.forEach((loc, idx) => {
      const locDateTime = loc.time.seconds ? new Date(loc.time.seconds * 1000) : new Date(loc.time);
      const dateTimeDisplay = getFormattedDateTime(locDateTime);
      const markerOptions = {
        map,
        position: {lat: loc.latitude, lng: loc.longitude},
        zIndex: idx === lastIndex ? 2 : 1,
        content: idx === lastIndex ? 
                          new markerLib.PinElement(greenMarkerOptions).element : 
                          new markerLib.PinElement(redMarkerOptions).element,
        title: `Altitude: ${loc.altitude?.toFixed(2)} metres, your time: ${dateTimeDisplay}, type: ${loc.type}`
      }
      newMarkers.push(new markerLib.AdvancedMarkerElement(markerOptions));
      console.log('added marker');
      bounds.extend(new window.google.maps.LatLng(loc.latitude, loc.longitude));
    })
          
    if (newMarkers.length > 0) {
      map.fitBounds(bounds);
    }
        
    //Set new markers
    markersRef.current = ([...newMarkers]);
    return true;
  }

  return (
    <Row>
      {
        //set markers using new location updates
        locs.length > 0 && setMarkers() && 
        <>
          <Col className='mb-2 col-lg-4 col-sm-12 col-md-8 bg-light p-1 rounded-pill text-center'>Viewing <Badge bg='info'>{getRiderNameDisplay()}</Badge> ride on <Badge bg='info'>{formatTextDate(rider.latestRideId)}</Badge></Col>
          <Col className='mb-2 col-lg-2 col-sm-12 col-md-4 bg-secondary bg-opacity-50 p-1 rounded-pill text-center'>Ride Status: <Badge bg={getRideStatus().color}>{getRideStatus().status}</Badge></Col>
          <Col className='mb-2 col-lg-3 col-sm-12 col-md-6 bg-light p-1 rounded-pill text-center'>Last Update: <Badge bg='info'>{getLastUpdateTime()}</Badge></Col>
          <Col className='mb-2 col-lg-2 col-sm-12 col-md-4 bg-secondary bg-opacity-50 p-1 rounded-pill text-center'>Update Count: <Badge bg='info'>{locs.length}</Badge></Col>
          <Col className='mb-2 col-lg-1 col-sm-12 col-md-2 bg-light p-1 rounded-pill text-center'><Link title='Hover over markers to see time and altitude' id='t-1'>Info</Link></Col>
        </>
      }
    </Row>
  );
}

export default RideMarkers;