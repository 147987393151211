import { useMap } from '@vis.gl/react-google-maps';
import { useUtils } from '../utils/utils';

const TourMap = ({rides}) => {
  const map = useMap();
  const { rideIdToDate, formatTextDate } = useUtils();
  const infoWindows = [];
  const lineOptions1 = {
    strokeColor: "#FF0000",
    strokeOpacity: 1.0,
    strokeWeight: 3,
  }
  const lineOptions2 = {
    strokeColor: "#0000FF",
    strokeOpacity: 1.0,
    strokeWeight: 3,
  }

  if (rides.length === 0 || !map) {
    return;
  }

  rides.sort((r1, r2) => {
    const r1Dt = rideIdToDate(r1.id);
    const r2Dt = rideIdToDate(r2.id);
    if (r1Dt > r2Dt) {
      return 1;
    } else if (r1Dt < r2Dt) {
      return -1;
    } else {
      return 0;
    }
  })

  const addMarkersWithInfoWindow = () => {
    rides.forEach(ride => {
      const index = Math.floor(ride.locations.length / 2); //Half way through the day
      const markerLocation = {lat: ride.locations[index].latitude, lng: ride.locations[index].longitude};
      const infoWindow = new window.google.maps.InfoWindow({
        content: `<h5>${formatTextDate(ride.id)}</h5>${ride.title}`, 
      });
      infoWindows.push(infoWindow);
      const marker = new window.google.maps.Marker({
        position: markerLocation,
        title: ride.id,
        map
      });

      //Will open one InfoWindow and close others
      marker.addListener('click', () => {
        infoWindow.open({anchor: marker, map});
        infoWindows.forEach(iw => {
          if (iw !== infoWindow) {
            iw.close();
          }
        })
      })
    })
  }

  const locations = 
    rides
      //Add ride date to each location
      .map(ride => {  
        return ride.locations.map(loc => ({...loc, rideDate: ride.id}))
      })
      //Combine locations from multiple ride dates into one array
      .reduce((acc, locations) => [...acc, ...locations], []) 
      //If location accuracy property is there, discard records with accuracy >= 500
      .filter(locn => locn.accuracy ? locn.accuracy < 500 : true)
      //return just the fields we need
      .map(locn => ({lat: locn.latitude, lng: locn.longitude, rideDate: locn.rideDate}))
  //console.log(locations.length, locations);
  
  let lastRideDate = '';
  let poly;
  let odd = true;

  const bounds = new window.google.maps.LatLngBounds();
  locations.forEach(locn => {
    const lat = locn.lat;
    const lng = locn.lng;
    
    const position = new window.google.maps.LatLng(lat, lng)
    
    //Color alternate day rides with red and blue lines
    if (lastRideDate !== locn.rideDate) {
      poly = new window.google.maps.Polyline(odd ? lineOptions1 : lineOptions2); 
      poly.setMap(map);
      odd = !odd;
      lastRideDate = locn.rideDate;
    }
    
    poly.getPath().push(position)
    bounds.extend(position);
  });

  map.fitBounds(bounds);
  addMarkersWithInfoWindow();
  return null;
}

export default TourMap;