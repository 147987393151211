import './EditTour.css';
import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PlusSquare, Trash } from 'react-bootstrap-icons';
import { useUtils } from '../utils/utils';
import useInput from '../utils/useInput';
import { useSelector } from 'react-redux';
import { selectTour } from '../features/userSelectionSlice';

function EditTour({show, closeModal, dayRides}) {
  const MAX_DAY_DIFF = 7;
  const {dateDiffInDays, formatTextDate} = useUtils();
  const tour = useSelector(selectTour);
  const title = useInput(tour.tourTitle);
  const description = useInput(tour.description);
  const [renderDays, setRenderDays] = useState([]);
  const [tourStartDate, setTourStartDate] = useState('');
  const [tourEndDate, setTourEndDate] = useState('');
  const [media, setMedia] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [validated, setValidated] = useState(false);


  useEffect(() => {
    const startDate = tour.dates[0];
    const endDate = tour.dates[tour.dates.length - 1];
    setTourStartDate(formatTextDate(startDate));
    setTourEndDate(formatTextDate(endDate));

    //show dates within 1 week of starting and ending tour dates
    const probableDates = dayRides
      .filter(dayRide => {
        return (dateDiffInDays(startDate, dayRide.id) <= MAX_DAY_DIFF ||
              dateDiffInDays(endDate, dayRide.id) <= MAX_DAY_DIFF) && 
              !tour.dates.includes(dayRide.id);
      })
      .map(dayRide => {
        return {selected: false, day: dayRide.id};
      })
    setRenderDays(probableDates);
    if (tour.media) {
      const tourMedia = tour.media.map((mi, idx) => {
        return {
          id: tour.id + '-' + idx,
          type: mi.type,
          link: mi.link
        }
      });
      setMedia(tourMedia);
    }
  }, [tour, dayRides, dateDiffInDays, formatTextDate])
  
  const handleClose = () => {
    closeModal(false);
  }

  const mediaItemChanged = (id, cell, event) => {
    const itemIndex = media.findIndex(mi => id === mi.id);
    const editedMi = {id}
    if (cell === 'type') {
      editedMi.type = event.target.value;
      editedMi.link = media[itemIndex].link;
    } else {
      editedMi.type = media[itemIndex].type;
      editedMi.link = event.target.value;
    }
    const updatedMedia = media.map((mi, idx) => {
      if (itemIndex === idx) {
        return editedMi;
      } else {
        return {...mi};
      }
    })
    setMedia(updatedMedia);
  }

  const addMediaRow = () => {
    if (media.some(mi => mi.type === '' || mi.link === '')) {
      setValidated(true);
      return;
    }
    setValidated(false);
    setErrorMessage('');
    setMedia([
      ...media, 
      {
        id: tour.id + '-' + new Date().getTime(),
        type: '',
        link: ''
      }
    ])
  }

  const deleteMediaRow = (id) => {
    const updatedMedia = media.filter(mi => mi.id !== id);
    setMedia(updatedMedia);
  }

  const toggleDay = (day) => {
    const newDaySelection = renderDays.map(rideDay => {
      if (rideDay.day === day) {
        return {selected: !rideDay.selected, day: rideDay.day};
      } 
      return {...rideDay};
    });
    setRenderDays(newDaySelection);
  }

  const mediaUpdated = () => {
    if (media.length !== tour.media?.length) {
      return true;
    }
    return tour.media.some((om, idx) => {
      return om.type !== media[idx].type || om.link !== media[idx].link;
    })
  }

  const submitForm = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      return;
      //event.stopPropagation();
    }

    setValidated(true);
    //Determine what has changed and set the data appropriately
    const changedData = {};
    if (title.value !== tour.tourTitle) {
      changedData.tourTitle = title.value;
    }
    if (description.value !== tour.description) {
      changedData.description = description.value;
    }
    if (mediaUpdated()) {
      changedData.media = media.map(mi => {
        return {link: mi.link, type: mi.type}
      });
    }
    const addedDays = renderDays
                        .filter(rd => rd.selected)
                        .map(rd => {
                          return rd.day;
                        });
    if (addedDays.length > 0) {
      changedData.dates = [...tour.dates, ...addedDays]
      changedData.addedDays = addedDays;
    }
    changedData.id = tour.id;
    closeModal(true, changedData);
  }

  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Manage Tour Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mb-3'>Tour Days: {tourStartDate} to {tourEndDate}</div>
        <Form onSubmit={submitForm} noValidate validated={validated} >
          <Form.Group className='mb-3'>
            <Form.Label>Title</Form.Label>
            <Form.Control type="text" 
              placeholder="Tour title" 
              value={title.value} 
              required
              onChange={title.onChange}
            />
            <Form.Control.Feedback type="invalid">
              Title is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Description</Form.Label>
            <Form.Control 
              as="textarea" 
              placeholder="Tour description" 
              rows={3} 
              value={description.value}
              required
              onChange={description.onChange}
            />
            <Form.Control.Feedback type="invalid">
              Description is required
            </Form.Control.Feedback>
          </Form.Group>
          
          {
            renderDays.length > 0 &&
            <Form.Group className='my-2'>
              <Form.Label>Select Additional Tour Dates</Form.Label>
              <div>
              {
                renderDays.map(rideDay => {
                  return  <ToggleButton
                            className='m-1'
                            key={rideDay.day}
                            id={`addDay_${rideDay.day}`}
                            type="checkbox"
                            variant="outline-primary"
                            size='sm'
                            checked={rideDay.selected}
                            value={rideDay.day}
                            onChange={(e) => toggleDay(e.currentTarget.value)}
                          >
                            {formatTextDate(rideDay.day)}
                          </ToggleButton>
                })
              }
              </div>
            </Form.Group>
          }
          <Form.Group className='mb-3'>
            <Form.Label>
              Media
              <PlusSquare 
                onClick={() => addMediaRow()} 
                className='mx-2 mediaEnabled'
              /> {errorMessage}
            </Form.Label>

            {
              media.map((mi, idx) => {
                return  <Row key={mi.id} className='mb-1'>
                          <Col lg={3} md={5} sm={12} xs={12}>
                            <Form.Control type="text" 
                              placeholder="Title" 
                              value={mi.type} 
                              required
                              onChange={(e) => mediaItemChanged(mi.id, 'type', e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              Type is required
                            </Form.Control.Feedback>
                          </Col>
                          <Col lg={8} md={6} sm={12} xs={12}>
                            <Form.Control type="url" 
                              placeholder="URL" 
                              value={mi.link} 
                              required
                              onChange={(e) => mediaItemChanged(mi.id, 'link', e)}
                            />
                            <Form.Control.Feedback type="invalid">
                              Enter a valid URL
                            </Form.Control.Feedback>
                          </Col>
                          <Col lg={1} md={1} sm={12} xs={12}>
                            <Trash 
                              onClick={() => deleteMediaRow(mi.id)} 
                              color='red'
                            />
                          </Col>
                        </Row>
              })
            }
          </Form.Group>
          <Button type='submit'>Save</Button>
          <Button onClick={handleClose} variant='outline-secondary' className='ms-2'>Cancel</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EditTour;