import { db } from '../firebase'
import { query, getDocs, collection, where, orderBy, doc, updateDoc } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import ImageWithTextOverlay from './ImageWithTextOverlay';
import { useSelector } from 'react-redux';
import { selectTour } from '../features/userSelectionSlice';

function SelectImage({show, closeModal, riderId}) {
  const [moments, setMoments] = useState([]);
  const tour = useSelector(selectTour);

  useEffect(() => {
    if (!riderId || !tour || tour.dates.length === 0) {
      return;
    }
    const momentsRef = collection(db, `/users/${riderId}/moments`);
    const q = query(momentsRef, where('date', 'in', tour.dates), orderBy('time'));
    getDocs(q)
      .then(docs => {
        const tourMoments = [];
        console.log('Number of moments: ', docs.docs.length);
        docs.forEach((doc) => {
          tourMoments.push({...doc.data(), id: doc.id});
        });
        setMoments(tourMoments);
      })
    
  }, [riderId, tour])

  const handleClose = () => {
    closeModal(false);
  }

  const onImageSelect = (picUrl) => {
    const tourRef = doc(db, `/users/${riderId}/tours/${tour.id}`);
    updateDoc(tourRef, { tourImage: picUrl })
      .then(() => {
        closeModal(true, {id: tour.id, tourImage: picUrl});
      })
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Choose a photo as Tour Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            moments.map(moment => {
              return (
                <div key={moment.id}>
                  <ImageWithTextOverlay 
                    moment={moment} 
                    clickAction={onImageSelect}
                  />
                </div>
              )
            })
          }
        </Modal.Body>

      </Modal>
    </>
  );
}

export default SelectImage;