import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/userSlice';
import riderReducer from '../features/selectedRiderSlice';
import userSelectionReducer from '../features/userSelectionSlice';

//The keys in the reducer are the store properties that is to be used
//to get the specific state
export const store = configureStore({
  reducer: {
    user: userReducer,
    selectedRider: riderReducer,
    userSelection: userSelectionReducer
  }
})