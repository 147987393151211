import './Home.css';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge'
import { useSelector } from 'react-redux';
import { selectUser } from '../features/userSlice';
import AvailableRiders from './AvailableRiders';
import { useLocation } from 'react-router-dom';

function Home() {
  const user = useSelector(selectUser);
  let location = useLocation();
  let content = <AvailableRiders/>;
  
  if (!user) {
    content = <p>Login (currently Google only) using the link above to see what others have shared</p>;
  } 
  return (
    <Container>
      <div className='mb-2'>
        Welcome to the Touring app. This app allows touring cyclists to share their adventures
        with the world. Touring cyclists use the native (currently iOS only) app to track their travels, share 
        their thoughts and pictures. 

        {user && <div>Choose a rider to see content in other tabs.</div>}
      </div>
      {location.state && <div className='mb-2'><Badge bg='danger'>{location.state.message}</Badge></div>}
      {content}
    </Container>
  );
}

export default Home;