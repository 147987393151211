import Container from 'react-bootstrap/Container';

const ManageContent = () => {
  return(
    <Container>
      Manage Content
    </Container>
  );
}

export default ManageContent;