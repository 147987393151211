import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import useInput from '../utils/useInput';
import { useState, useEffect } from 'react';
import { useUtils } from '../utils/utils';
import { useSelector } from 'react-redux';
import { selectRide } from '../features/userSelectionSlice';

function AddTour({show, closeModal, dayRides}) {
  const [renderDays, setRenderDays] = useState([]);
  const selectedRide = useSelector(selectRide);
  const title = useInput('');
  const description = useInput('');
  const {formatTextDate, rideIdToDate} = useUtils();

  useEffect(() => {
    const daySelection = dayRides.map(dayRide => {
      if (dayRide.id === selectedRide.id) {
        return {selected: true, day: dayRide.id};
      } else {
        return {selected: false, day: dayRide.id};
      }
    })

    console.log(daySelection);
    setRenderDays(daySelection);
  }, [dayRides, selectedRide]);

  const handleClose = () => {
    closeModal(false, 'newTour');
    resetForm();
  }

  const toggleDay = (day) => {
    console.log(day);
    const newDaySelection = renderDays.map(rideDay => {
      if (rideDay.day === day) {
        return {selected: !rideDay.selected, day: rideDay.day};
      } 
      return {...rideDay};
    });
    console.log(newDaySelection);
    setRenderDays(newDaySelection);
  }

  const submitForm = (event) => {
    event.preventDefault();
    const selectedDates = renderDays
                            .filter(renderDay => renderDay.selected)
                            .map(renderDay => renderDay.day)
    console.log(selectedDates);
    closeModal(true, 'newTour', {
        tourTitle: title.value, 
        description: description.value, 
        rideId: selectedRide.id, 
        dates: selectedDates
      }
    );
    resetForm();
  }

  const resetForm = () => {
    title.reset();
    description.reset();
  }

  const saveDisabled = () => {
    //description value will be undefined for new rides
    return (title.value === '' || description.value === '' || description.value === undefined);
  }

  //Sort render days by absolute day difference and use this to render the dates
  const daySelection = renderDays.map(renderDay => {
    const absDateDiff = Math.abs(rideIdToDate(renderDay.day) - rideIdToDate(selectedRide.id));
    return {...renderDay, absDateDiff};
  })
  daySelection.sort((a, b) => {
    if (a.absDateDiff > b.absDateDiff) {
      return 1;
    } else if (a.absDateDiff < b.absDateDiff) {
      return -1;
    } else {
      return 0;
    }
  })

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add to new Tour</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Ride Day: {formatTextDate(selectedRide.id)}</h5>
        <Form onSubmit={submitForm}>
          <Form.Group className='mb-3'>
            <Form.Label>Title</Form.Label>
            <Form.Control type="text" 
              placeholder="Tour title" 
              value={title.value} 
              onChange={title.onChange}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Description</Form.Label>
            <Form.Control 
              as="textarea" 
              placeholder="Tour description" 
              rows={3} 
              value={description.value}
              onChange={description.onChange}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Select Additional Tour Dates</Form.Label>
            <div>
            {
              daySelection.map(rideDay => {
                return  <ToggleButton
                          className='m-1'
                          key={rideDay.day}
                          id={`addDay_${rideDay.day}`}
                          type="checkbox"
                          variant="outline-primary"
                          size='sm'
                          disabled={rideDay.day === selectedRide.id}
                          checked={rideDay.selected}
                          value={rideDay.day}
                          onChange={(e) => toggleDay(e.currentTarget.value)}
                        >
                          {formatTextDate(rideDay.day)}
                        </ToggleButton>
              })
            }
            </div>
          </Form.Group>
          <Button type='submit' disabled={saveDisabled()}>Save</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddTour;