import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import useInput from '../utils/useInput';
import { useUtils } from '../utils/utils';
import { useSelector } from 'react-redux';
import { selectRide } from '../features/userSelectionSlice';

function EditRide({show, closeModal}) {
  const selectedRide = useSelector(selectRide);
  const description = useInput(selectedRide.title);
  const {formatTextDate} = useUtils();

  const handleClose = () => {
    closeModal(false, 'editRide');
    resetForm();
  }

  const submitForm = (event) => {
    event.preventDefault();
    closeModal(true, 'editRide', {title: description.value, id: selectedRide.id});
    resetForm();
  }

  const resetForm = () => {
    description.reset();
  }

  const saveDisabled = () => {
    //description value will be undefined for new rides
    return description.value === '' || description.value === undefined;
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Ride Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{formatTextDate(selectedRide.id)}</h5>
        <Form onSubmit={submitForm}>
          <Form.Group className='mb-3'>
            <Form.Label>Description</Form.Label>
            <Form.Control 
              as="textarea" 
              placeholder="Brief description of the day ride" 
              rows={3} 
              value={description.value}
              onChange={description.onChange}
            />
          </Form.Group>
          <Button type='submit' disabled={saveDisabled()}>Save</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EditRide;