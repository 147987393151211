import { createSlice } from "@reduxjs/toolkit";

export const userSelectionSlice = createSlice({
  name: 'userSelection',
  initialState: { value: null},
  reducers: {
    setRide: (state, action) => {
      state.value = action.payload;
    }, 
    setTour: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { setRide, setTour } = userSelectionSlice.actions;
export const selectRide = state => state.userSelection.value;
export const selectTour = state => state.userSelection.value;
export default userSelectionSlice.reducer;