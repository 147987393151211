import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup, signOut} from "firebase/auth";
import { getFirestore, where, doc, getDoc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDplhjsSpU2updHpQ6YbfiFKvvSv9y87ew",
  authDomain: "touring-aids.firebaseapp.com",
  projectId: "touring-aids",
  storageBucket: "touring-aids.appspot.com",
  messagingSenderId: "9774063639",
  appId: "1:9774063639:web:b94eb536d17bb62fbc7c52",
  measurementId: "G-PK7FK1JE8P"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider)
    console.log(res.user);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
}
const logout = () => {
  signOut(auth);
}

export { auth, db, signInWithGoogle, logout, where, doc, getDoc }