import './AvailableRiders.css';
import { useEffect, useState } from 'react';
import { db } from '../firebase'
import { collection, orderBy, query, where, getDocs } from 'firebase/firestore';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Image from 'react-bootstrap/Image';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { selectUser } from '../features/userSlice';
import { selectRider } from '../features/selectedRiderSlice';
import useRider from '../utils/useRider';

function AvailableRiders() {
  const user = useSelector(selectUser);
  const selectedRider = useSelector(selectRider);
  const [riderId, setRiderId] = useState(selectedRider?.uid);
  const [riders, setRiders] = useState([]);
  useRider(riderId);

  useEffect(() => {
    const usersRef = collection(db, '/users');
    const q = query(usersRef, orderBy('name'), where('showRides', '==', true));
    getDocs(q)
      .then(querySnapshot => {
        const availableRiders = [];
        
        querySnapshot.forEach((doc) => {
          const {latestRide, ...userDoc} = doc.data();
          availableRiders.push({uid: doc.id, ...userDoc, latestRideId: latestRide?.id});
        })
        console.log('Number of riders: ', availableRiders.length);
        availableRiders.some(rider => rider.uid === user.uid) ? 
          setRiders(availableRiders) : 
          setRiders([{...user}, ...availableRiders]);
      })
      .catch(err => console.error(err));
  }, [user]);

  const onChooseRider = (selectedRider) => {
    console.log(`Rider chosen. Previous: ${riderId}. New: ${selectedRider.uid}`);
    setRiderId(selectedRider.uid);
    //store in local storage for future access
    const cachedRiderKey = `CHOSEN_RIDER_${user.uid}`;
    localStorage.setItem(cachedRiderKey, selectedRider.uid);
  }
  const getChosenRider = () => {
    if (selectedRider) {
      return(selectedRider.name);
    } 
    return 'None';
  }

  return(
    user && selectedRider && <div>
      <span className='bg-light p-1 rounded-pill me-1'>
        Available Riders <Badge className='bg-info'>{riders.length}</Badge>
      </span>
      <span className='bg-secondary bg-opacity-50 p-1 rounded-pill me-1'>
        Currently chosen rider <Badge className='bg-info'>{getChosenRider()}</Badge>
      </span>
      <Image src={selectedRider.photoURL} roundedCircle fluid className='avatar' />
      
      <h3>Choose a Rider</h3>
      <ListGroup>
      {
        riders.map(rider => {
          const label = rider.uid === user.uid ? 'Self' : rider.name;
          let content = <Button as="a" variant='link' onClick={() => onChooseRider(rider)}>
                          {label}
                        </Button>
          if (selectedRider && selectedRider.uid === rider.uid) {
            content = <><span className='self'>{label}</span><CheckCircleFill/></>;
          }
          return (
            <ListGroup.Item key={rider.uid}>
              <Image src={rider.photoURL} roundedCircle fluid className='avatar' />
              {content}
            </ListGroup.Item>
          )
        }) 
      }
      </ListGroup>
    </div>
  );
}

export default AvailableRiders;