import { useState } from "react";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import PlaceholderPicture from '../assets/placeholder.png';
import { useUtils } from "../utils/utils";

const ImageWithTextOverlay = ({moment, clickAction}) => {
  const [showModal, setShowModal] = useState(false);
  const { getFormattedDateTime } = useUtils();
  const imageLoaded = (event) => {
    event.target.hidden = false;
    document.getElementById(moment.id).setAttribute('hidden', 'true');
  }

  const toggleModal = () => {
    setShowModal(currState => !currState);
  }

  return (
    <div style={{position: 'relative', display: 'inline-block'}}>
      <Modal show={showModal} onHide={toggleModal} size="lg">
        <Modal.Body>
          <Image src={moment.pictureUrl} width='100%'/>
        </Modal.Body>
      </Modal>

      <img id={moment.id} src={PlaceholderPicture} alt="Placeholder" width="100%" className='pb-2'/>
      <Image 
        hidden
        onLoad={imageLoaded}
        src={moment.pictureUrl} 
        width='100%' className='pb-2'
        onClick={clickAction ? () => clickAction(moment.pictureUrl) : toggleModal}
      />
      <div style={{
        backgroundColor: "black",
        color: "white",
        padding: "6px",
        borderRadius: "10px",
        opacity: "0.6",
        position: 'absolute',
        top: '5px', 
        left: '5px' 
      }}>{getFormattedDateTime(moment.time)}</div>
    </div>
  )
}

export default ImageWithTextOverlay;