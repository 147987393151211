import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../features/userSlice';
import { selectRider } from '../features/selectedRiderSlice';

const ProtectedRoute = ({redirectPath = '/', children, ownerOnly}) => {
  const user = useSelector(selectUser);
  const selectedRider = useSelector(selectRider);
  if (!user) {
    return <Navigate to={redirectPath} state={{message: 'Login to access to access other tabs'}} replace />;
  }
  if (ownerOnly && user.uid !== selectedRider.uid) {
    return <Navigate to={redirectPath} state={{message: 'You can only manage your own content'}} replace />;
  }

  return children ? children : <Outlet />
} 

export default ProtectedRoute;

/* Usage
  //Use as a wrapping component
  <Route
    path="dashboard"
    element={
      <ProtectedRoute> 
        <Dashboard />
      </ProtectedRoute>
    }
  />
  OR
  //Use as a layout component
  <Route element={<ProtectedRoute/>}>
    <Route path="home" element={<Home />} />
    <Route path="dashboard" element={<Dashboard />} />
  </Route>
*/