import { useCallback } from "react";
const useUtils = () => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const DATE_TIME_DISPLAY_FORMAT = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  }
  const getFormattedDateTime = (date) => {
    if (typeof date === 'number') {
      return new Intl.DateTimeFormat('default', DATE_TIME_DISPLAY_FORMAT).format(new Date(date));
    }
    return new Intl.DateTimeFormat('default', DATE_TIME_DISPLAY_FORMAT).format(date);
  }

  const formatTextDate = useCallback((textDate) => {
    return textDate.substring(0,2) + '-' + 
          textDate.substring(2, 5) + '-' + 
          textDate.substring(5, 9);
  }, []);

  const rideIdToDate = (textDate) => {
    return new Date(parseInt(textDate.substring(5, 9)),
                    months.indexOf(textDate.substring(2, 5)), 
                    parseInt(textDate.substring(0, 2))
    )
  }

  const dateDiffInDays = useCallback((date1Str, date2Str) => {
    const msDiff = Math.abs(new Date(date1Str).getTime() - new Date(date2Str).getTime());
    return Math.round(msDiff / (24 * 60 * 60 * 1000));
  }, []);

  return {getFormattedDateTime, formatTextDate, rideIdToDate, dateDiffInDays};
}
export { useUtils };