import { db } from '../firebase'
import { query, getDocs, collection, doc, setDoc, addDoc } from 'firebase/firestore';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import { ImageFill, PencilSquare, PlusSquare } from 'react-bootstrap-icons';
import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { selectRider } from '../features/selectedRiderSlice'
import { selectUser } from '../features/userSlice';
import SelectImage from './SelectImage';
import EditTour from './EditTour';
import AddTour from './AddTour';
import { useUtils } from '../utils/utils';
import PlaceholderPicture from '../assets/placeholder.png';
import { setRide, setTour } from '../features/userSelectionSlice';
import { useDispatch } from 'react-redux';
import EditRide from './EditRide';

function Tours() {
  const dispatch = useDispatch();
  const {formatTextDate} = useUtils();
  const selectedRider = useSelector(selectRider);
  const user = useSelector(selectUser);

  const [tours, setTours] = useState([]);
  const [dayRides, setDayRides] = useState([]);
  
  const [activeTab, setActiveTab] = useState('multiDayTours');
  const [showISModal, setShowISModal] = useState(false);
  const [showETModal, setShowETModal] = useState(false);
  const [showNTModal, setShowNTModal] = useState(false);
  const [showERModal, setShowERModal] = useState(false);

  const getTours = useCallback(async () => {
    const myTours = [];
    const multiDayTourDays = [];
    const allRideDays = [];
    
    console.log('Getting', `/users/${selectedRider.uid}/tours`);
    let q = query(collection(db, `/users/${selectedRider.uid}/tours`));
    let docs = await getDocs(q);
    console.log('Number of tours: ', docs.docs.length);
    docs.forEach((doc) => {
      myTours.push({...doc.data(), id: doc.id});
      multiDayTourDays.push(...doc.data().dates)
    });
    setTours(myTours.sort(sortByDt));

    //getDayRides
    q = query(collection(db, `/users/${selectedRider.uid}/rides`));
    docs = await getDocs(q);
    console.log('Number of ride days: ', docs.docs.length);
    docs.forEach((doc) => {
      allRideDays.push({id: doc.id, title: doc.data().title});
    });
    const mySingleDayRides = [];
    allRideDays.forEach(ride => {
      if (!multiDayTourDays.includes(ride.id)) {
        mySingleDayRides.push(ride);
      }
    });
    setDayRides(mySingleDayRides.sort(sortByDt));
    console.log(myTours, mySingleDayRides);
    console.log(myTours.length, mySingleDayRides.length);
  }, [selectedRider]);

  useEffect(() => {
    if (selectedRider) {
      getTours();
    }
  }, [selectedRider, getTours]);

  /**
   * 
   * @param {*} a - ride, tour, or date string (ddMMMyyyy)
   * @param {*} b - ride, tour, or date string (ddMMMyyyy)
   * @returns 
   */
  const sortByDt = (a, b) => {
    let dtA;
    let dtB;
    let direction = 'desc';
    if (a.dates) {
      dtA = new Date(a.dates[0]);
      dtB = new Date(b.dates[0]);
    } else if (a.id) {
      dtA = new Date(a.id);
      dtB = new Date(b.id);
    } else {
      //Sort dates array in ascending order
      direction = 'asc';
      dtA = new Date(a);
      dtB = new Date(b);
    }
  
    if (dtA < dtB) {
      if (direction === 'desc') {
        return 1;
      } else {
        return -1;
      }
    } else if (dtA > dtB) {
      if (direction === 'desc') {
        return -1;
      } else {
        return 1;
      }
    } else {
      return 0;
    }
  }

  const selectImage = (tour) => {
    console.log(tour.id);
    dispatch(setTour(tour));
    setShowISModal(true);
  }

  const closeISModalAndRefresh = (dataUpdated, data) => {
    if (dataUpdated) {
      const {id, tourImage} = data;
      const updatedTours = tours.map(tour => {
        if (tour.id === id) {
          return {...tour, tourImage};
        } else {
          return {...tour};
        }
      })
      setTours(updatedTours);
    }
    
    setShowISModal(false);
  }

  const editTour = (tour) => {
    console.log(tour);
    dispatch(setTour(tour));
    setShowETModal(true);
  }

  const closeETModalAndRefresh = (dataUpdated, data) => {
    if (dataUpdated) {
      const {id: tourId, addedDays, ...updatedTourData} = data;
      if (updatedTourData.dates) {
        updatedTourData.dates.sort(sortByDt);
        //remove any added days from day rides
        const updatedDayRides = dayRides.filter(dayRide => {
          return !addedDays.includes(dayRide.id);
        });
        setDayRides(updatedDayRides);
      }
      console.log(data);
      setDoc(
        doc(db, `/users/${selectedRider.uid}/tours/${tourId}`),
        updatedTourData, 
        {merge: true}
      )
      .then(() => {
        console.log('Udpated tour with id', tourId)
        //update content on client side. Just title changing. 
        const updatedTours = tours.map(tour => {
          if (tour.id === tourId) {
            return {...tour, id: tourId, ...updatedTourData};
          } else {
            return {...tour};
          }
        })
        setTours(updatedTours);
      })
    }

    setShowETModal(false);
  }

  const manageRide = (type, ride) => {
    console.log(ride);
    dispatch(setRide(ride));
    if (type === 'editRide') {
      setShowERModal(true);
    } else {
      setShowNTModal(true);
    }
  }

  const doneManageRide = (haveData, modalName, data) => {
    if (haveData) {
      console.log(modalName, data);
      if (modalName === 'editRide') {
        setDoc(
          doc(db, `/users/${selectedRider.uid}/rides/${data.id}`),
          {title: data.title}, 
          {merge: true}
        )
        .then(() => {
          console.log('Udpated ride id', data.id)
          //update content on client side. Just title changing. 
          const updatedDayRides = dayRides.map(dayRide => {
            if (dayRide.id === data.id) {
              return {id: data.id, title: data.title};
            } else {
              return {...dayRide};
            }
          })
          setDayRides(updatedDayRides);
        })
      } else {
        const {rideId, dates, ...tourDoc} = data;
        
        addDoc(
          collection(db, `/users/${selectedRider.uid}/tours`),
          {...tourDoc, dates: dates.sort(sortByDt)}
        )
        .then((tour) => {
          console.log('added new tour', tour.id)
          //update content on client side.
          const updatedTours = [{...tourDoc, id: tour.id, dates}, ...tours];
          setTours(updatedTours.sort(sortByDt));
          setActiveTab('multiDayTours');
        })
      }
    }
    
    if (modalName === 'editRide') {
      setShowERModal(false);
    } else {
      setShowNTModal(false);
    }
  }

  const sameUser = () => {
    return user.uid === selectedRider.uid;
  }

  return <Container><Tabs
    activeKey={activeTab}
    className="my-3"
    onSelect={key => setActiveTab(key)}
  >
    <Tab eventKey="multiDayTours" title="Multi-day Tours">
      {selectedRider && showISModal &&
        <SelectImage 
          show={showISModal} closeModal={closeISModalAndRefresh} 
          riderId={selectedRider.uid}
        />
      }
      {selectedRider && showETModal &&
        <EditTour 
          show={showETModal} closeModal={closeETModalAndRefresh} 
          dayRides={dayRides}
        />
      }
      <Row xs={1} md={2} lg={4} className="g-4">
        { tours.map(tour => {
            const rideCount = tour.dates.length;
            let img = <Card.Img variant="top" src={PlaceholderPicture} />;
            if (tour.tourImage) {
              img = <Card.Img variant="top" src={tour.tourImage} />;
            }
            return (
              <Col key={tour.id}>
                <Card>
                  <Card.Body>
                    <Link to={tour.id}>{img}</Link>
                    <div className='d-flex justify-content-between align-items-center mb-2'>
                      <Card.Title className='mb-0'>{tour.tourTitle}</Card.Title>
                      {
                        sameUser() && 
                        <div>
                          <PencilSquare className='me-2' onClick={() => editTour(tour)}/>
                          <ImageFill onClick={() => selectImage(tour)}/>
                        </div>
                      }
                    </div>
                    <Card.Subtitle className="mb-2 text-muted">
                      {formatTextDate(tour.dates[0])} to {formatTextDate(tour.dates[rideCount - 1])}
                    </Card.Subtitle>
                    <Card.Text>
                      {tour.description}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            )
          })
        }
      </Row>
    </Tab>
    <Tab eventKey="dayRides" title="Day Rides">
      {selectedRider && showNTModal && 
        <AddTour 
          show={showNTModal} closeModal={doneManageRide} 
          dayRides={dayRides}
        />
      }
      {selectedRider && showERModal && 
        <EditRide 
          show={showERModal} closeModal={doneManageRide} 
        />
      }
      <Row xs={1} md={2} lg={4} className="g-4">
          { dayRides.map(ride => {
              return (
                <Col key={ride.id}>
                  <Card>
                    <Card.Body>
                      <div className='d-flex justify-content-between align-items-center mb-2'>
                        <Card.Title className='mb-0'>{formatTextDate(ride.id)}</Card.Title>
                        {
                          sameUser() && 
                          <div>
                            <PencilSquare className='me-2' onClick={() => manageRide('editRide', ride)}/>
                            <PlusSquare onClick={() => manageRide('newTour', ride)}/>
                          </div>
                        }
                      </div>
                      <Card.Text>
                        {ride.title}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              )
            })
          }
        </Row>
    </Tab>
  </Tabs></Container>
}

export default Tours;