import { useParams, useNavigate } from "react-router-dom";
import { useUtils } from '../utils/utils';
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { selectRider } from '../features/selectedRiderSlice';
import TourMap from "./TourMap";
import {APIProvider, Map} from '@vis.gl/react-google-maps';

import { db } from '../firebase'
import { query, getDocs, collection, where, orderBy, doc, getDoc } from 'firebase/firestore';

import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ImageWithTextOverlay from "./ImageWithTextOverlay";
import Button from 'react-bootstrap/Button';

const TourDetails = () => {
  const position = {lat: 12.9716, lng: 77.5946}; //Bangalore
  const tourId = useParams().tourId;
  const navigate = useNavigate();
  const selectedRider = useSelector(selectRider);
  const [rides, setRides] = useState([]);
  const [moments, setMoments] = useState([]);
  const [tour, setTour] = useState(null);

  const {formatTextDate} = useUtils();

  useEffect(() => {
    //get tour
    if (!selectedRider) {
      return;
    }
    const tourDocRef = doc(db, `/users/${selectedRider.uid}/tours/${tourId}`)
    getDoc(tourDocRef)
      .then(docSnap => {
        if (docSnap.exists()) {
          setTour({id: docSnap.id, ...docSnap.data()});
          
          //get tour moments
          const momentsRef = collection(db, `/users/${selectedRider.uid}/moments`);
          const momentsQuery = query(momentsRef, where('date', 'in', docSnap.data().dates), orderBy('time'));
          getDocs(momentsQuery)
            .then(docs => {
              const tourMoments = [];
              console.log('Number of moments: ', docs.docs.length);
              docs.forEach((doc) => {
                tourMoments.push({...doc.data(), id: doc.id});
              });
              console.log(tourMoments);
              setMoments(tourMoments);
            })

          //Get locations
          const ridesRef = collection(db, `/users/${selectedRider.uid}/rides`);
          //using __name__ to filter by document id
          const ridesQuery = query(ridesRef, where('__name__', 'in', docSnap.data().dates));
          getDocs(ridesQuery)
            .then(docs => {
              const tourRides = [];
              console.log('Number of ride days: ', docs.docs.length);
              docs.forEach((doc) => {
                tourRides.push({...doc.data(), id: doc.id});
              });
              console.log(tourRides);
              setRides(tourRides);
            })
        }
      })
  }, [tourId, selectedRider])

  if (!tour) {
    return null;
  }

  let mediaContent = '';  
  if (tour.media) {
    mediaContent = tour.media.map((mi, idx) => {
      return <Card.Link href={mi.link} target="_blank" key={`${tour.id}-${idx}`}>{mi.type}</Card.Link>
    });
  }
  return(
    <Container>
      <Card>
        <Card.Body>
          <div className="card-title d-flex justify-content-between mb-3">
            <h4>{tour.tourTitle}</h4>
            <Button as="a" variant='link' onClick={() => navigate('/tours')}>Back to all Tours</Button>
          </div>
          {/* <Card.Title className="mb-3">{tour.tourTitle}</Card.Title> */}
          <Card.Subtitle className="mb-2">{formatTextDate(tour.dates[0])} to {formatTextDate(tour.dates[tour.dates.length - 1])}</Card.Subtitle>
          {mediaContent}
          <Card.Text className="my-2">{tour.description}</Card.Text>
          
          <Tabs defaultActiveKey="photos">
            <Tab eventKey="photos" title="Photos">
              <Row xs={1} md={2} lg={4} className="g-4 pt-1">
                { moments.map(moment => {
                    return (
                      <Col key={moment.id}>
                        <Card>
                          <ImageWithTextOverlay 
                            moment={moment}
                          />
                          <Card.Subtitle>{moment.title}</Card.Subtitle>
                          <Card.Text>{moment.description}</Card.Text>
                        </Card>
                        
                          {/* Add title here later */}
                      </Col>
                    )
                  })
                }
                {moments.length === 0 && <div>No Pictures</div>}
              </Row>
            </Tab>
            <Tab eventKey="routeMap" title="Route Map">
              <div style={{height: '75vh'}}>
                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                  <TourMap rides={rides} />
                  <Map  scaleControl={true} mapId='process.env.REACT_APP_MAP_ID' center={position} zoom={16} >
                  </Map>
                </APIProvider>
              </div>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </Container>
  ) 
}

export default TourDetails;