import Container from 'react-bootstrap/Container';
import {APIProvider, Map} from '@vis.gl/react-google-maps';
import RideMarkers from './RideMarkers';

function LatestRide() {
  const position = {lat: 12.9716, lng: 77.5946}; //Bangalore
  
  return (
    <Container style={{height: '85vh'}}>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <RideMarkers></RideMarkers>
        <Map scaleControl={true} center={position} zoom={16} mapId='process.env.REACT_APP_MAP_ID'>
        </Map>
      </APIProvider>
    </Container>
  )
}

export default LatestRide;