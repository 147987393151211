import './App.css';
import Menu from './components/Menu';
import Tours from './components/Tours';
import Home from './components/Home';
import TourDetails from './components/TourDetails';
import LatestRide from './components/LatestRide';
import ManageContent from './components/ManageContent';
import Container from 'react-bootstrap/Container';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';

import { auth, db } from './firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useAuthState } from "react-firebase-hooks/auth";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUser, unsetUser } from './features/userSlice';
import useRider from './utils/useRider';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  const [ready, setReady] =  useState(false);
  const [error, setError] = useState('');
  const [riderId, setRiderId] = useState(null);
  useRider(riderId);
  const dispatch = useDispatch();
  console.log('Environment:',process.env.NODE_ENV);
  useAuthState(auth, {
    onUserChanged: (user) => {
      console.log('Came to onUserChanged', user);
      if (user) {
        const userRef = doc(db, `/users/${user.uid}`);
        getDoc(userRef)
          .then(userSnapshot => {
            if (userSnapshot.exists()) {
              processExistingUser(userSnapshot)
            } else {
              processNewUser(user);
            }
          })
          .catch(err => {
            setError(`Error getting existing user or setting new user: ${err.message}`);
            console.error(err);
          })
      } else {
        setAppState(); //reset state
      }
    }
  });

  const setAppState = (user) => {
    console.log('in setAppState()');
    
    if (user) {
      const cachedRiderKey = `CHOSEN_RIDER_${user.uid}`;
      let cachedRiderId = localStorage.getItem(cachedRiderKey);
      //set state
      dispatch(setUser(user));
      if (!cachedRiderId) {
        cachedRiderId = user.uid;
        //cache the chosen rider
        localStorage.setItem(cachedRiderKey, cachedRiderId);
      } else if (cachedRiderId.startsWith('{')) {
        //handle old cached data type where we were storing full rider object instead of just the id
        console.log('Changing old cached format to new format');
        const cachedRider = JSON.parse(cachedRiderId);
        cachedRiderId = cachedRider.uid;
        localStorage.setItem(cachedRiderKey, cachedRiderId);
      }
      setRiderId(cachedRiderId);
    } else {
      //unset state
      dispatch(unsetUser());
      setRiderId(null);
    }
    
    console.log('setting ready to true');
    setReady(true);
  }

  const processExistingUser = (userSnapshot) => {
    const {latestRide, ...userDoc} = userSnapshot.data();
    console.log(userDoc);
    console.log('Setting user redux state to: ', {...userDoc, uid: userSnapshot.id, latestRideId: latestRide?.id});
    setAppState({...userDoc, uid: userSnapshot.id, latestRideId: latestRide?.id});
  }

  const processNewUser = (user) => {
    //create new db user
    const newUser = {
      authProvider: 'google',
      email: user.email,
      name: user.displayName,
      photoURL: user.photoURL,
      showRides: false
    }
    setDoc(doc(db, `/users/${user.uid}`), newUser)
      .then(() => {
        setAppState({...newUser, uid: user.uid});
      })
      .catch(err => {
        setError(`Error: ${err.message}`);
        console.error(err);
      })
  }

  if (!ready) {
    return(
      <div>
        {!error && <div className='loading'>Setting up your experience. Please wait...</div>}
        {error && <div className='loading'>{error}</div>}
      </div>
    ) 
  }
  return (
    <BrowserRouter>
      <Menu />
      <div className='app'>
        <Routes>
          <Route path="/" element={<Outlet />}>
            <Route index element={<Home />} />
            <Route element={<ProtectedRoute/>} >
              <Route path="/tours" element={<Tours />} />
              <Route path="/tours/:tourId" element={<TourDetails />}/>
              <Route path="/latestRide" element={<LatestRide />} />
            </Route>
            <Route 
              path='/manageContent'
              element={
                <ProtectedRoute ownerOnly>
                  <ManageContent />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  )
}

const NotFound = () => {
  return (<Container>Not Found: 404! Please use application navigation. </Container>);
};

export default App;
