import { db } from '../firebase'
import { doc, onSnapshot } from 'firebase/firestore';
import { setRider, unsetRider } from '../features/selectedRiderSlice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const useRider = (riderId) => {
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(`Setting new rider: ${riderId}`);
    if (!riderId) {
      dispatch(unsetRider());
      return;
    }
    const riderRef = doc(db, `/users/${riderId}`)
  
    const riderUnsubscribe = onSnapshot(riderRef, riderSnapshot => {
      const {latestRide, ...riderDoc} = riderSnapshot.data();
      dispatch(setRider({...riderDoc, uid: riderSnapshot.id, latestRideId: latestRide?.id}));
    });

    return () => {
      console.log('Unsubscribing rider');
      riderUnsubscribe();
    }
  }, [riderId, dispatch])
}

export default useRider;